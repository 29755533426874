body {
  font-family: 'Open Sans', sans-serif;
  color: #000;
}

hr {
  width: 50%;
  height: 4px;
  border: 0;
  position: relative;
  left: 0;
  background-color: #2a3034;
}


.topBorder {
  width: 100%;
  height: 8px;
  border: 0;
  position: absolute;
  top: -0px;
  padding: 0;
  margin: 0;
  left: 0;
  background-color: rgba(256, 256, 256, 0.9);
}

.App {
  text-align: center;
  color: #000;
}


.App-header {
  background: rgb(177,194,198);
  background: linear-gradient(180deg, rgba(177,194,198,1) 0%, rgba(241,241,241,1) 100%);
  min-height: calc(100vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header .content {
  margin: 4%;
  padding: 4%;
  background-color: #FFF;
  color: #000;
  font-size: 12px;
  text-align: left;
}

.App-header .content a{
  text-decoration: underline;
  color: #000;
}

.App-link {
  color: #61dafb;
}

.innerCard {
  background-color: rgba(256, 256, 256, 1);
  padding: 4%;
  border-radius: 0px;
  margin: 4%;
  margin-top: 20px;
  color: black;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.0);
}

.innerCard .small {
  font-size: 12px;
  opacity: 0.6;
  position: relative;
  top: -10px;
}

.innerCard hr {
  width: 100%;
  height: 4px;
  border: 0;
  position: relative;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
}

.AppHeader {
  font-family: 'Futura', sans-serif;
  margin-top: 70px;
  color: #2a3034;
  font-size: calc(10px + 4vmin);
}

.AppHeader a {
  color: #2a3034;
  text-decoration: none;
}

.AppHeader .aboutThaSite {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  max-width: 500px;
  padding: 4px 10px;
  margin: 0 auto;
}

.AppFooter {
  max-width: 500px;
  padding: 4px 10px;
  font-size: 12px;
  color: #000;
}

.AppFooter a {
  text-decoration: underline;
  font-size: 12px;
  color: #000;
}

.margiTopMeNegative {
  margin-top: -16px;
  position: relative !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
